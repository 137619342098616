import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import Home from "./views/Home";
import Confirmation from "./views/Step10_Confirmation";
import TicketDownload from "./views/_Ticket_Download";

// Fallback page
import PageNotFound from "./views/components/PageNotFound";
import InvalidPromocode from "./views/components/InvalidPromocode";

import "./css/style.css";

function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="*" element={<PageNotFound />} />
                <Route path="/" element={<Navigate to="/de/home/ethtag2024" replace />} />
                <Route path="/:language/home/:code" element={<Home />} />
                <Route path="/:language/confirmation/:orderId" element={<Confirmation />} />
                <Route path="/:language/invalidpromocode" element={<InvalidPromocode />} />
                <Route path="/:language/download/:orderId/:promocode" element={<TicketDownload />} />
            </Routes>
        </BrowserRouter>
    );
}

export default App;
